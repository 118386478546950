import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';


import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import ArticleExcerpt from '../components/slices/ArticleExcerpt/ArticleExcerpt';

const PodcastTemplate = ({ data, pageContext }) => {
  if (!data) return null;
  const { allPodcasts } = pageContext;
  const podcastContent = data.prismicPodcast;
  const podcast = podcastContent.data || {};

  const { lang, type, url } = podcastContent;
  const alternateLanguages = podcastContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = podcastContent.data.meta_title;
  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={podcast.body} />
      <ArticleExcerpt podcasts={allPodcasts.collection} />
    </Layout>
  );
};

export const query = graphql`
query podcastQuery($uid: String, $lang: String) {
  prismicPodcast(uid: {eq: $uid}, lang: {eq: $lang}) {
    url
    uid
    type
    id
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    _previewable  
    data {
      section
      display_title {
        html
        raw
        text
      }
      meta_title
      body {
        ... on PrismicPodcastDataBodyFullWidthMedia {
          id
          items {
            location {
              html
              raw
              text
            }
          }
          primary {
            title_font_size
            description {
              html
              raw
              text
            }
            alt
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2, placeholder: BLURRED,
                      blurredOptions: { width: 250, toFormat: AUTO})
                }
              }
            }
            title {
              html
              raw
              text
            }
            title_color
            text_position
            description_position
            margin_top
            other_ways_to_listen
          }
          slice_label
          slice_type
        }
        ... on PrismicPodcastDataBodyFullWidthImageWithTextBelow {
          id
          slice_type
          slice_label
          primary {
            title_color
            title {
              html
              raw
              text
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            has_stream_buttons
            description_color
            description_position
            description {
              html
              text
              raw
            }
          }
        }
      }
    }
  }
  prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
  }
  prismicFooter(lang: {eq: $lang}) {
    ...FooterFragment
  }
}

`;

export default withPrismicPreview(PodcastTemplate);
