import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import './ArticleExcerpt.scss';
import Tags from '../../Tags/Tags';

const ArticleExcerpt = ({ podcasts }) => (
  <section className="article-excerpt">
    <div className="all-articles">
      {podcasts.map((item, index) => {
        function createMarkup() {
          const output = item.embedInfo.html ? item.embedInfo.html.replace('" height="200"', '?&hide_share=true" height="52') : '';
          return { __html: output };
        }
        return (
          <div
            className={`article-container ${index % 2 === 0 ? 'left' : 'right'}`}
            key={item.id}
          >
            <Link to={`/podcast/episode/${item.slug}`}>
              <img
                className="image"
                src={item.image_url}
                alt="Podcast Logo"
              />
            </Link>
            <div className="info-wrapper">
              <div className="text-wrapper">
                <Link to={`/podcast/episode/${item.slug}`}>
                  <div className="title">
                    <span className="episode">
                      EPISODE&nbsp;
                      {item.number > 9 ? item.number : `0${item.number}`}
                      :
                    </span>
                    <span className="space">&nbsp;</span>
                    {item.title.toUpperCase()}
                  </div>
                </Link>
                <div className="description">
                  {item.description}
                  <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
              </div>
              <div className="interactables">
                <div className="button-and-share-wrapper">
                  <Tags
                    url={`https://cloud.army/podcast/episode/${item.slug}`}
                    podcast
                    desktop
                    alignment={index % 2 === 0 ? 'left' : 'right'}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </section>
);
export default ArticleExcerpt;
